export const namespaced = true;

export const state = {
  todos: [],
  filterPage: "all",
};
export const getters = {
  todoLength: (state) => {
    const todoItems = state.todos.filter((todo) => todo.status === "todo");
    return todoItems.length;
  },

  doneLength: (state) => {
    const doneItems = state.todos.filter((todo) => todo.status === "done");
    return doneItems.length;
  },

  filterPageList: (state) => {
    if (state.filterPage === "todo") {
      return state.todos.filter((todo) => todo.status === "todo");
    }

    if (state.filterPage === "done") {
      return state.todos.filter((todo) => todo.status === "done");
    }

    return state.todos;
  },
};
export const mutations = {
  ADD_TODO(state, payload) {
    state.todos.push({ id: payload, item: payload, status: "todo" });
  },

  DELETE_TODO(state, payload) {
    const toDoItem = state.todos.findIndex((todo) => todo.id === payload);
    state.todos.splice(toDoItem, 1);
  },

  UPDATE_STATUS_TODO(state, payload) {
    const toDoItem = state.todos.findIndex((todo) => todo.id === payload);
    state.todos[toDoItem].status =
      state.todos[toDoItem].status === "todo" ? "done" : "todo";
  },

  UPDATE_FILTER_PAGE(state, payload) {
    state.filterPage = payload;
  },

  CLEAR_COMPLETED_TODOS(state) {
    state.todos = state.todos.filter((todo) => todo.status === "todo");
  },

  GET_LOCAL_STORAGE(state, payload) {
    if (payload.todos) {
      state.todos = JSON.parse(payload.todos);
    }

    if (payload.filterPage) {
      state.filterPage = JSON.parse(payload.filterPage);
    }
  },
};
export const actions = {
  addToDo({ commit }, payload) {
    commit("ADD_TODO", payload);
  },

  deleteToDo({ commit }, payload) {
    commit("DELETE_TODO", payload);
  },

  updateStatusToDo({ commit }, payload) {
    commit("UPDATE_STATUS_TODO", payload);
  },

  updateFilterPage({ commit }, payload) {
    commit("UPDATE_FILTER_PAGE", payload);
  },

  clearCompletedToDos({ commit }) {
    commit("CLEAR_COMPLETED_TODOS");
  },

  getLocalStorage({ commit }, payload) {
    commit("GET_LOCAL_STORAGE", payload);
  },
};
